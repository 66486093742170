import React from "react"
import { Link } from "gatsby"
import { RiCloseLine, RiTwitterFill } from 'react-icons/ri';

const MenuItems = [
  {
    path: "https://twitter.com/LifeTechPsych",
    title: "Follow on Twitter"
  },
  // {
  //   path: "/blog",
  //   title: "✨All Notes"
  // },
  // {
  //   path: "/",
  //   title: "Home"
  // },
  // {
  //   path: "/contact",
  //   title: "Contact"
  // },
]

const ListLink = (props) => (<li><Link to={props.to}>{props.children}</Link></li>)


class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showMenu: false}

    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick() {
    this.setState(state => ({      
      showMenu: !state.showMenu    
    }))
  }

  render () {
    const listMenuItems = MenuItems.map((menuItem, index) => 
      <ListLink key={index} to={menuItem.path}>
          <span><RiTwitterFill/></span>
        {menuItem.title}
      </ListLink>
    )
    return (
      <nav className="site-navigation">
        <button onClick={this.handleToggleClick} className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}>
          {/* <div className="icon-menu-line"><RiMenu3Line/></div> */}
          {/* <div className="icon-menu-close"><RiCloseLine/></div> */}
          {/*  className="icon-menu-close"><RiTwitterFill/></div> */}
          <div className="icon-menu-line"><RiTwitterFill/></div>
          <div className="icon-menu-close"><RiCloseLine/></div>
        </button>
        <ul>
          {listMenuItems}
        </ul>
      </nav>
    )
  }
}

export default Navigation
