import React from "react"
// import {Link} from "gatsby"
import { RiFileDownloadLine, RiTwitterLine } from "react-icons/ri";

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p> Upgrading the developer journey, one by one.</p>
      <p>
        <span className="icon -love"><RiTwitterLine/></span> 
        {/* <Link to="https://twitter.com/LifeTechPsych">Follow @LifeTechPsych</Link> |  */}
        {/* <Link to="https://lifetechpsych.ck.page/">FREE Dev cheatsheet</Link> */}
        <a href="https://twitter.com/LifeTechPsych">Follow @LifeTechPsych</a> | 
        {/* <span className="icon -love"><RiCommunityLine/></span>  */}
        <span className="icon -love"><RiFileDownloadLine/></span> 
        <a href="https://lifetechpsych.ck.page/">Get free dev cheatsheet</a>
      </p>
    </div>
  </footer>
)

export default Footer